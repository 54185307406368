import React, {useState} from 'react';
import Header from '../components/Header';
import Expandable from '../components/Expandable';
import season2122 from '../data/stats2021_2022/season21-22.json';
import {StatHelper} from '../services/statHelper';
import StatTabs from "../components/StatTabs";
import StatsTable from "../components/StatsTable";

export default function Stats(): React.JSX.Element {
    const mobile = window.innerWidth < 550;

    const [statType, setStatType] = useState('season');
    //some react magic or bullshit
    const [changeTable, setChangeTable] = useState(false);

    function table_2021_2022(): React.JSX.Element {
        const players: JSX.Element[] = [];
        season2122.forEach((player) => {
            const textClass =
                player.player === 'Team'
                    ? 'bg-white font-bold text-center border-2 border-black'
                    : 'text-white border-2 border-red-600 text-center';

            players.push(
                <tr key={player.player} className="border-red-900 border-4">
                    <td className={textClass}>{player.player}</td>
                    <td className={textClass}>{player.wat3}</td>
                    <td className={textClass}>{player.phalanx}</td>
                    <td className={textClass}>{player.klosterneuburg}</td>
                    <td className={textClass}>{player.wat22}</td>
                    <td className={textClass}>{player.flames6}</td>
                    <td className={textClass}>{player.basketfighters}</td>
                    <td className={textClass}>{player.flames4}</td>
                    <td className={textClass}>{player.average}</td>
                    <td className={textClass}>{player.pointsTotal}</td>
                    <td className={textClass}>{player.gamesPlayed}</td>
                </tr>
            );
        });

        const thClassName = 'text-white border-2 border-red-600';
        return (
            <table>
                <thead>
                <tr className="border-red-900 border-4">
                    <th className={thClassName}>PLAYER</th>
                    <th className={thClassName}>WAT&nbsp;3</th>
                    <th className={thClassName}>PHALANX</th>
                    <th className={thClassName}>KLOSTERNEUBURG</th>
                    <th className={thClassName}>WAT&nbsp;22</th>
                    <th className={thClassName}>FLAMES&nbsp;6</th>
                    <th className={thClassName}>EUROFIGHTERS</th>
                    <th className={thClassName}>FLAMES&nbsp;4</th>
                    <th className={thClassName}>AVERAGE</th>
                    <th className={thClassName}>TOTAL&nbsp;POINTS</th>
                    <th className={thClassName}>GAMES&nbsp;PLAYED</th>
                </tr>
                </thead>
                <tbody>{players}</tbody>
            </table>
        );
    }

    const [openSeasonId, setOpenSeasonId] = useState(
        process.env.REACT_APP_CURRENT_SEASON as string
    );

    const onExpandableCLick = (id: string): void => {
        setOpenSeasonId(id);
    };

    const table = (): JSX.Element => {
        if (statType === "season") {
            return (<div className="flex flex-col items-center">
                <Expandable
                    id="24-25"
                    openSeasonId={openSeasonId}
                    onClick={onExpandableCLick}
                    stats={true}
                >
                    <StatsTable averages={StatHelper.getSeasonAverages("2024-2025")}></StatsTable>
                </Expandable>
                <Expandable
                    id="23-24"
                    openSeasonId={openSeasonId}
                    onClick={onExpandableCLick}
                    stats={true}
                >
                    <StatsTable averages={StatHelper.getSeasonAverages("2023-2024")}></StatsTable>
                </Expandable>
                <Expandable
                    id="22-23"
                    openSeasonId={openSeasonId}
                    onClick={onExpandableCLick}
                    stats={true}
                >
                    <StatsTable averages={StatHelper.getSeasonAverages("2022-2023")}></StatsTable>
                </Expandable>
                <Expandable
                    id="21-22"
                    openSeasonId={openSeasonId}
                    onClick={onExpandableCLick}
                    stats={true}
                >
                    {table_2021_2022()}
                </Expandable>
            </div>)
        } else if (statType === "career") {
            return <div className="flex flex-col items-center w-full mt-2">
                <div className={`flex-col w-full m-2 block`}>
                    <p className="text-white text-center text-lg">
                        &#11088; denotes team record. You can scroll/swipe right on the table on mobile!
                    </p>
                    <div
                        className={`xl:flex xl:justify-center overflow-x-auto`}
                    >
                        <StatsTable setTable={changeTable} averages={StatHelper.getCareerAverages()}></StatsTable>
                    </div>
                </div>
            </div>
        } else {
            return <div className="flex flex-col items-center w-full mt-2">
                <div className={`flex-col w-full m-2 block`}>
                    <p className="text-white text-center text-lg">
                        &#11088; denotes team record. You can scroll/swipe right on the table on mobile!
                    </p>
                    <div
                        className={`xl:flex xl:justify-center overflow-x-auto`}
                    >
                        <StatsTable setTable={changeTable} averages={StatHelper.getSingleGameBests()}></StatsTable>
                    </div>
                </div>
            </div>
        }
    }

    return (
        <div>
            <Header/>
            <StatTabs small={mobile || false} initialType={statType} onClick={statType => {
                setStatType(statType);
                setChangeTable(!changeTable)
            }}></StatTabs>
            {table()}
        </div>
    )
        ;
}

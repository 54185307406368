import React, {useLayoutEffect, useRef, useState} from 'react';
import Header from '../components/Header';
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';
import YoutubeHelper from '../services/youtubeHelper';
import {useParams} from 'react-router-dom';

import {responsiveCarousel} from '../services/mobileHelper';
import StatsTable from "../components/StatsTable";
import {type stat, StatHelper} from "../services/statHelper";

export default function Post(): React.JSX.Element {
    const {season, fileName} = useParams();
    const postFile = require('../posts/' + season + '/' + fileName + '.json');
    let boxScore: stat[] = [];

    if (postFile.match) {
        boxScore = require('../data/boxScores/' + fileName + '.json');
    }

    const [fullScreen, setFullScreen] = useState(false);
    const [fullScreenImage, setFullScreenImage] = useState('');

    const videoContainerRef = useRef<HTMLDivElement>(null);
    const [videoWidth, setVideoWidth] = useState(0);

    useLayoutEffect(() => {
        if (videoContainerRef.current) {
            setVideoWidth(videoContainerRef.current.offsetWidth);
        }
    }, []);

    function youtubeElement(): React.JSX.Element {
        const youtubeMarkup = YoutubeHelper.getEmbedHTML(
            postFile.video,
            videoWidth
        );
        return (
            <div
                dangerouslySetInnerHTML={{
                    __html: youtubeMarkup === null ? '' : youtubeMarkup
                }}
            ></div>
        );
    }

    function fullScreenButton(image: string): React.JSX.Element {
        return (
            <div
                className="cursor-pointer"
                onClick={() => {
                    setFullScreen(!fullScreen);
                    setFullScreenImage(image);
                }}
            >
                <svg height="50" width="50" version="1.1" viewBox="0 0 36 36">
                    <rect width="100%" height="100%" fill="black"/>
                    <path
                        fill="#DC2626"
                        d="m 10,16 2,0 0,-4 4,0 0,-2 L 10,10 l 0,6 0,0 z"
                    ></path>
                    <path
                        fill="#DC2626"
                        d="m 20,10 0,2 4,0 0,4 2,0 L 26,10 l -6,0 0,0 z"
                    ></path>
                    <path
                        fill="#DC2626"
                        d="m 24,24 -4,0 0,2 L 26,26 l 0,-6 -2,0 0,4 0,0 z"
                    ></path>
                    <path
                        fill="#DC2626"
                        d="M 12,20 10,20 10,26 l 6,0 0,-2 -4,0 0,-4 0,0 z"
                    ></path>
                </svg>
            </div>
        );
    }

    function images(): React.JSX.Element | React.JSX.Element[] {
        const imageElements: React.JSX.Element[] = [];
        postFile.images.forEach((image: string) => {
            imageElements.push(
                <div key={image} className="relative flex justify-center">
                    <div className="absolute top-0 right-0">
                        {fullScreenButton(image)}
                    </div>
                    <img src={image} alt="lok" className="max-h-[32rem]"/>
                </div>
            );
        });
        if (imageElements.length === 1) {
            return imageElements;
        } else {
            return (
                <Carousel responsive={responsiveCarousel} infinite={false}>
                    {imageElements}
                </Carousel>
            );
        }
    }

    return (
        <div>
            <Header/>
            <div className={fullScreen ? 'visible' : 'hidden'}>
                <div className="relative w-full">
                    <div className="absolute top-0 right-0">
                        {fullScreenButton(fullScreenImage)}
                    </div>
                    <img width="100%" src={fullScreenImage} alt="lok"/>
                </div>
            </div>
            <div className={fullScreen ? 'hidden' : 'visible'}>
                <div className="flex justify-center">
                    <div className="w-3/4">{images()}</div>
                </div>
            </div>

            <div className="flex text-white justify-center">
                <div className="md:w-2/4 w-full px-5">
                    <h1 className="text-center m-2">
                        <b>{postFile.title}</b>
                    </h1>
                    <p dangerouslySetInnerHTML={{__html: postFile.text}}></p>
                    <p className="m-2 text-right text-xs">posted on {postFile.date}</p>
                </div>
            </div>
            {postFile.match && (
                <>
                    <h1 className="text-center text-white m-2">
                        <b>BOX SCORE</b>
                    </h1>
                    <div className="flex justify-center my-5 flex-col">
                        <p className="text-white text-center text-lg">
                            You can scroll/swipe right on the table on mobile! &#11088; denotes team record.
                        </p>
                        <div className="w-full xl:flex xl:justify-center overflow-x-auto">
                            <StatsTable singleGameTable={true} setTable={true} averages={boxScore}
                                        compareAverages={StatHelper.getSingleGameBests()}></StatsTable>
                        </div>
                    </div>
                </>
            )}
            {postFile.video && (
                <>
                    <h1 className="text-center text-white m-2">
                        <b>FULL REPLAY</b>
                    </h1>
                    <div className="flex justify-center my-5">
                        <div ref={videoContainerRef} className="md:w-2/4 w-full">
                            {youtubeElement()}
                        </div>
                    </div>
                </>
            )}
        </div>
    );
}

import React from 'react';
import './App.css';
import Header from './components/Header';
import {useNavigate} from 'react-router-dom';
import postList from './posts/postList.json';

export default function App(): React.JSX.Element {
    const navigate = useNavigate();

    const VIEW_PORT_CUT_OFF = +(process.env.REACT_APP_VIEW_PORT_CUTOFF as string);

    const TEXT_SIZE = 400;

    //to truncate the text in posts
    function truncate(source: string, size: number): string {
        return source.length > size ? source.slice(0, size - 1) + '…' : source;
    }

    function posts(): React.JSX.Element[] {
        const postElements: React.JSX.Element[] = [];
        const postListFlat: string[] = [];
        //add new season every year
        postListFlat.push(...postList.posts['2022-2023']);
        postListFlat.push(...postList.posts['2023-2024']);
        postListFlat.push(...postList.posts['2024-2025']);
        postListFlat
            .sort(function (a, b) {
                return b.localeCompare(a);
            })
            .forEach((postName) => {
                //shitty hardcoded code, add new season next year
                let season = '2022-2023';
                // eslint-disable-next-line
                if ((postList.posts['2023-2024'] as string[]).includes(postName)) {
                    season = '2023-2024';
                } //todo years in the future
                if (postList.posts['2024-2025'].includes(postName)) {
                    season = '2024-2025';
                } //todo years in the future

                const post = require('./posts/' + season + '/' + postName + '.json');

                if (window.innerWidth < VIEW_PORT_CUT_OFF) {
                    postElements.push(
                        <div
                            key={postName}
                            className="cursor-pointer flex justify-center w-3/4 p-2 my-5 border-2 rounded-md border-red-600"
                            onClick={() => {
                                navigate('/post/' + season + '/' + postName);
                            }}
                        >
                            <div className="relative">
                                <div className="absolute top-5 w-full bg-black opacity-50 h-5"></div>
                                <div className="absolute top-5 w-full text-center text-white h-5">
                                    {post.title}
                                </div>
                                <img src={'/thumbnails/' + postName + '.png'} alt="lok"/>
                            </div>
                        </div>
                    );
                } else {
                    postElements.push(
                        <div
                            key={postName}
                            className="cursor-pointer flex w-3/4 p-2 my-5 border-2 rounded-md border-red-600 relative"
                            onClick={() => {
                                navigate('/post/' + season + '/' + postName);
                            }}
                        >
                            <div className="self-center w-1/4 mr-5">
                                <img src={'/thumbnails/' + postName + '.png'} alt="lok"/>
                            </div>
                            <div className="self-center w-3/4">
                                <h1 className="text-center text-white mb-2">
                                    <b>{post.title}</b>
                                </h1>
                                <div className="text-center text-white">
                                    {truncate(
                                        post.text.replace(/<\/?[^>]+(>|$)/g, ''),
                                        TEXT_SIZE
                                    )}
                                </div>
                            </div>
                            <div className="text-center text-white absolute bottom-0 right-0 mr-2">
                                {'posted on ' + post.date}
                            </div>
                        </div>
                    );
                }
            });
        return postElements;
    }

    return (
        <div>
            <Header/>
            <div className="flex flex-col items-center">{posts()}</div>
        </div>
    );
}

import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import {BrowserRouter, Route, Routes} from 'react-router-dom';
import Roster from './routes/roster';
import Stats from './routes/stats';
import Schedule from './routes/schedule';
import Results from './routes/results';
import Post from './routes/post';

const root = ReactDOM.createRoot(
    document.getElementById('root') as HTMLElement
);
root.render(
    <React.StrictMode>
        <BrowserRouter>
            <Routes>
                <Route path="/" element={<App/>}/>
                <Route path="roster" element={<Roster/>}/>
                <Route path="schedule" element={<Schedule/>}/>
                <Route path="results" element={<Results/>}/>
                <Route path="stats" element={<Stats/>}/>
                <Route path="post/:season/:fileName" element={<Post/>}/>
            </Routes>
        </BrowserRouter>
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log stats (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

import React, {useState} from 'react';

type StatTabsProps = {
    onClick: (statType: string) => void;
    initialType: string;
    small: boolean;
};

export default function StatTabs(props: StatTabsProps): React.JSX.Element {
    const [statType, setStatType] = useState(props.initialType);

    return (
        <div className={props.small ? "" : "flex flex-row justify-center w-full"}>
            <div
                className={"text-xl flex-row flex justify-center text-white text-center " + (props.small ? "" : "w-1/2")}>
                <div
                    className={`border-2 rounded-s-xl cursor-pointer border-red-900 hover:border-red-600 p-1 px-2 w-1/3 hover:font-extrabold hover:bg-red-600 ${
                        statType === 'career' ? 'bg-red-900' : ''
                    }`}
                    onClick={() => {
                        setStatType('career');
                        props.onClick("career")
                    }}
                >
                    Career
                </div>
                <div
                    className={`border-2 cursor-pointer border-red-900 hover:border-red-600 p-1 px-2 w-1/3 hover:font-extrabold hover:bg-red-600 ${
                        statType === 'season' ? 'bg-red-900' : ''
                    }`}
                    onClick={() => {
                        setStatType('season');
                        props.onClick("season")
                    }}
                >
                    Season
                </div>
                <div
                    className={`border-2 rounded-e-xl cursor-pointer border-red-900 hover:border-red-600 p-1 px-2 w-1/3 hover:font-extrabold hover:bg-red-600 ${
                        statType === 'best' ? 'bg-red-900' : ''
                    }`}
                    onClick={() => {
                        setStatType('best');
                        props.onClick("best")
                    }}
                >
                    Best
                </div>
            </div>
        </div>
    )
}

import React, {useEffect, useState} from 'react';
import {type stat} from "../services/statHelper";
import TableHelper from "../services/tableHelper";
import RecordStar from "./RecordStar";

type StatsTableProps = {
    averages: stat[];
    compareAverages?: stat[];
    setTable?: boolean;
    singleGameTable?: boolean; //this indicates whether to put record star in teams line and additionally determines if gp column should be shown
};

export default function StatsTable(props: StatsTableProps): React.JSX.Element {
    const singleGameTable = props.singleGameTable === undefined ? false : props.singleGameTable
    const compareAverages = props.compareAverages ? props.compareAverages : props.averages

    const [asc, setAsc] = useState(true)

    useEffect(() => {
        setPlayers(getPlayers("name", asc, singleGameTable))
    }, [props.setTable])

    const getPlayers = (sortField: string, asc: boolean, singleGameTable: boolean): React.JSX.Element[] => {
        const players: React.JSX.Element[] = [];
        props.averages.sort(function (a, b) {
            //this first if else puts Team in the last row
            if (a.name === 'Team') {
                return 1;
            } else if (b.name === 'Team') {
                return -1;
            }

            return TableHelper.sortRows(a, b, sortField, asc);
        }).forEach((player) => {
            const textClass =
                player.name === 'Team'
                    ? 'bg-white font-bold text-center border-2 border-black'
                    : 'text-white border-2 border-red-600 text-center';
            players.push(
                <tr key={player.name} className="border-red-900 border-t-2">
                    <td
                        className={textClass + ' absolute w-36 -ml-36 -mt-0.5 bg-black'}
                    >
                        {player.name}
                    </td>
                    <td className={textClass}>{player.no}</td>
                    {!singleGameTable
                        ? (<td className={textClass}>{player.gamesPlayed}<RecordStar
                            playerNo={player.no}
                            stats={props.averages} max={true}
                            compareStats={compareAverages}
                            statField={"gamesPlayed"}
                            singleGameTable={singleGameTable}/>
                        </td>) : ("")
                    }
                    <td className={textClass}>{TableHelper.formatNumber(player.fgMade2pt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages} max={true}
                        compareStats={compareAverages}
                        statField={"fgMade2pt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fgAtt2pt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages} max={true}
                        compareStats={compareAverages}
                        statField={"fgAtt2pt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fg2pt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"fg2pt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fgMade3pt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages} max={true}
                        compareStats={compareAverages}
                        statField={"fgMade3pt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fgAtt3pt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages} max={true}
                        compareStats={compareAverages}
                        statField={"fgAtt3pt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fg3pt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"fg3pt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fgMade)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"fgMade"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fgAtt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"fgAtt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.fg)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"fg"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.ftMade)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"ftMade"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.ftAtt)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"ftAtt"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.ft)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"ft"} singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.offRebound)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"offRebound"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.defRebound)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"defRebound"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.reboundTot)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"reboundTot"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.assist)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"assist"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.turnover)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages} max={false}
                        compareStats={compareAverages}
                        statField={"turnover"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.block)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"block"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.steal)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"steal"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.personalFoul)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={false}
                        compareStats={compareAverages}
                        statField={"personalFoul"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.points)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"points"}
                        singleGameTable={singleGameTable}/>
                    </td>
                    <td className={textClass}>{TableHelper.formatNumber(player.eff)}<RecordStar
                        playerNo={player.no}
                        stats={props.averages}
                        max={true}
                        compareStats={compareAverages}
                        statField={"eff"}
                        singleGameTable={singleGameTable}/>
                    </td>
                </tr>
            );
        });
        return players
    }

    const [players, setPlayers] = useState(getPlayers("name", asc, singleGameTable))

    const thClassName = 'cursor-pointer text-white border-2 border-red-600';

    return (
        <table className="ml-36">
            <thead>
            <tr>
                {/*use this character if you want to have non line breaking space: &nbsp;   */}
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('name', asc, singleGameTable));
                    }}
                    className={thClassName + ' absolute w-36 -ml-36 -mt-0.5 bg-black'}
                >
                    Player
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('no', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    No
                </th>
                {!singleGameTable
                    ? <th
                        onClick={() => {
                            setAsc(!asc)
                            setPlayers(getPlayers('gamesPlayed', asc, singleGameTable));
                        }}
                        className={thClassName}
                    >
                        GP
                    </th> : ("")
                }
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgMade2pt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    2FGM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgAtt2pt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    2FGA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fg2pt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    2FG%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgMade3pt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    3FGM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgAtt3pt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    3FGA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fg3pt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    3FG%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgMade', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    FGM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fgAtt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    FGA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('fg', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    FG%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('ftMade', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    FTM
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('ftAtt', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    FTA
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('ft', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    FT%
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('offRebound', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    OREB
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('defRebound', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    DREB
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('reboundTot', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    REB
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('assist', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    AST
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('turnover', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    TO
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('block', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    BLK
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('steal', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    STL
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('personalFoul', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    PF
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('points', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    PTS
                </th>
                <th
                    onClick={() => {
                        setAsc(!asc)
                        setPlayers(getPlayers('eff', asc, singleGameTable));
                    }}
                    className={thClassName}
                >
                    EFF
                </th>
            </tr>
            </thead>
            <tbody>{players}</tbody>
        </table>
    );
}

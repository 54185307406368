import React from 'react';
import {useLocation, useNavigate} from 'react-router-dom';

type HeaderButtonProps = {
    link: string;
    text: string;
};

export default function HeaderButton(
    props: HeaderButtonProps
): React.JSX.Element {
    const navigate = useNavigate();
    const {pathname} = useLocation();
    const active = pathname.includes(props.text.toLowerCase());

    return (
        <div
            className="md:m-4 m-0.5 p-1 text-red-800 border-black border-2 hover:text-red-600 hover:border-2 hover:rounded-md hover:border-red-600 cursor-pointer"
            onClick={() => {
                navigate(props.link);
            }}
            onAuxClick={() => {
                window.open(props.link, '_blank');
            }}
        >
            <h1
                className={`md:text-2xl text-xl font-[collegiate] font-bold ${
                    active ? 'underline' : ''
                }`}
            >
                {props.text}
            </h1>
        </div>
    );
}

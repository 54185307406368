import React from 'react';
import {getStatField, type stat} from '../services/statHelper';

type RecordStarProps = {
    stats: stat[];
    compareStats: stat[];
    playerNo?: number;
    max: boolean;
    statField: string;
    singleGameTable: boolean;
};

function maxStat(stats: stat[], statField: string): stat {
    return stats.reduce(function (prev, current) {
        const prevField = getStatField(prev, statField);
        const currentField = getStatField(current, statField);
        return prev && prevField > currentField ? prev : current;
    }); //returns object
}

function minStat(stats: stat[], statField: string): stat {
    return stats.reduce(function (prev, current) {
        const prevField = getStatField(prev, statField);
        const currentField = getStatField(current, statField);
        return prev && prevField < currentField ? prev : current;
    }); //returns object
}

export default function RecordStar(props: RecordStarProps): React.JSX.Element {
    if (props.playerNo === undefined) {
        if (!props.singleGameTable) {
            return <></>
        }
        const teamStat = props.stats.filter((stat) => stat.no === undefined)[0]
        const teamCompareStat = props.compareStats.filter((stat) => stat.no === undefined)[0]
        const field = getStatField(teamStat, props.statField)
        const compareField = getStatField(teamCompareStat, props.statField)
        if (field === compareField) {
            return <>&#11088;</>
        } else {
            return <></>
        }
    }

    const stats = props.stats.filter((stat) => stat.no !== undefined);
    const compareStats = props.compareStats.filter((stat) => stat.no !== undefined);

    const bestPlayer = props.max
        ? maxStat(compareStats, props.statField)
        : minStat(compareStats, props.statField);

    if (bestPlayer.gamesPlayed === 0) {
        return <></>;
    }

    const bestPlayers = stats
        .filter((stat) => {
            const field = getStatField(stat, props.statField);
            const bestField = getStatField(bestPlayer, props.statField); //called best because it could be max or min
            return field === bestField;
        })
        .map((stat) => stat.no);

    if (bestPlayers.includes(props.playerNo)) {
        return (
            <>&#11088;</>
        );
    } else {
        return <></>;
    }
}

import postList from '../posts/postList.json';

export type stat = {
    name: string;
    no: number;
    fgMade2pt: number;
    fgAtt2pt: number;
    fg2pt: number;
    fgMade3pt: number;
    fgAtt3pt: number;
    fg3pt: number;
    fgMade: number;
    fgAtt: number;
    fg: number;
    ftMade: number;
    ftAtt: number;
    ft: number;
    points: number;
    offRebound: number;
    defRebound: number;
    reboundTot: number;
    assist: number;
    turnover: number;
    block: number;
    steal: number;
    personalFoul: number;
    eff: number;
    gamesPlayed: number;
};

export const getStatField = (
    stat: stat,
    statField: string
): number | string => {
    switch (statField) {
        case 'name':
            return stat.name;
        case 'no':
            return stat.no;
        case 'fgMade2pt':
            return stat.fgMade2pt;
        case 'fgAtt2pt':
            return stat.fgAtt2pt;
        case 'fg2pt':
            return stat.fg2pt;
        case 'fgMade3pt':
            return stat.fgMade3pt;
        case 'fgAtt3pt':
            return stat.fgAtt3pt;
        case 'fg3pt':
            return stat.fg3pt;
        case 'fgMade':
            return stat.fgMade;
        case 'fgAtt':
            return stat.fgAtt;
        case 'fg':
            return stat.fg;
        case 'ftMade':
            return stat.ftMade;
        case 'ftAtt':
            return stat.ftAtt;
        case 'ft':
            return stat.ft;
        case 'points':
            return stat.points;
        case 'offRebound':
            return stat.offRebound;
        case 'defRebound':
            return stat.defRebound;
        case 'reboundTot':
            return stat.reboundTot;
        case 'assist':
            return stat.assist;
        case 'turnover':
            return stat.turnover;
        case 'block':
            return stat.block;
        case 'steal':
            return stat.steal;
        case 'personalFoul':
            return stat.personalFoul;
        case 'eff':
            return stat.eff;
        case 'gamesPlayed':
            return stat.gamesPlayed;
        default:
            return stat.name;
    }
};

const getSeasons = (): string[] => {
    const seasons: string[] = [];
    for (const index in postList.posts) {
        seasons.push(index);
    }
    return seasons
}

const calculateCareerAverages = function calculateCareerAverages(
    zeroes: boolean = false
): stat[] {
    const seasons = getSeasons()
    return calculateSeasonAverages(seasons, zeroes);
}

const calculateCareerSingleGameBest = function calculateCareerSingleGameBest(): stat[] {
    const seasons = getSeasons()
    return calculateSeasonSingleGameBest(seasons);
}

const calculateSeasonAverages = function calculateAverages(
    seasons: string[],
    zeroes: boolean = false
): stat[] {
    const boxScores: stat[][] = [];

    type postListType = typeof postList.posts; //wtf typescript is a pain in my ass
    seasons.forEach((season) => {
        postList.posts[season as keyof postListType].forEach((postName) => {
            const post = require('../posts/' + season + '/' + postName + '.json');
            if (post.match) {
                const boxScore = require('../data/boxScores/' + postName + '.json');
                boxScores.push(boxScore);
            }
        });
    });

    const averages: stat[] = [];
    const sums: stat[] = [];

    boxScores.forEach((boxScore) => {
        boxScore.forEach((player) => {
            const foundPlayer = sums.find(
                (playerSum) => playerSum.name === player.name
            );
            if (foundPlayer === undefined) {
                player.gamesPlayed = 1;
                sums.push(player);
            } else {
                const foundPlayerIndex = sums.findIndex(
                    (playerSum) => playerSum.name === player.name
                );
                const sum: stat = {
                    name: foundPlayer.name,
                    no: foundPlayer.no,
                    fgMade2pt: foundPlayer.fgMade2pt + player.fgMade2pt,
                    fgAtt2pt: foundPlayer.fgAtt2pt + player.fgAtt2pt,
                    fg2pt: foundPlayer.fg2pt + player.fg2pt,
                    fgMade3pt: foundPlayer.fgMade3pt + player.fgMade3pt,
                    fgAtt3pt: foundPlayer.fgAtt3pt + player.fgAtt3pt,
                    fg3pt: foundPlayer.fg3pt + player.fg3pt,
                    fgMade: foundPlayer.fgMade + player.fgMade,
                    fgAtt: foundPlayer.fgAtt + player.fgAtt,
                    fg: foundPlayer.fg + player.fg,
                    ftMade: foundPlayer.ftMade + player.ftMade,
                    ftAtt: foundPlayer.ftAtt + player.ftAtt,
                    ft: foundPlayer.ft + player.ft,
                    points: foundPlayer.points + player.points,
                    offRebound: foundPlayer.offRebound + player.offRebound,
                    defRebound: foundPlayer.defRebound + player.defRebound,
                    reboundTot: foundPlayer.reboundTot + player.reboundTot,
                    assist: foundPlayer.assist + player.assist,
                    turnover: foundPlayer.turnover + player.turnover,
                    block: foundPlayer.block + player.block,
                    steal: foundPlayer.steal + player.steal,
                    personalFoul: foundPlayer.personalFoul + player.personalFoul,
                    eff: foundPlayer.eff + player.eff,
                    gamesPlayed: foundPlayer.gamesPlayed + 1
                };
                sums[foundPlayerIndex] = sum;
            }
        });
    });

    sums.forEach((player) => {
        const gameCount = player.gamesPlayed;
        const avg: stat = {
            name: player.name,
            no: player.no,
            fgMade2pt: player.fgMade2pt / gameCount,
            fgAtt2pt: player.fgAtt2pt / gameCount,
            fg2pt:
                player.fgAtt2pt === 0
                    ? 0
                    : (player.fgMade2pt / player.fgAtt2pt) * 100,
            fgMade3pt: player.fgMade3pt / gameCount,
            fgAtt3pt: player.fgAtt3pt / gameCount,
            fg3pt:
                player.fgAtt3pt === 0
                    ? 0
                    : (player.fgMade3pt / player.fgAtt3pt) * 100,
            fgMade: player.fgMade / gameCount,
            fgAtt: player.fgAtt / gameCount,
            fg: player.fgAtt === 0 ? 0 : (player.fgMade / player.fgAtt) * 100,
            ftMade: player.ftMade / gameCount,
            ftAtt: player.ftAtt / gameCount,
            ft: player.ftAtt === 0 ? 0 : (player.ftMade / player.ftAtt) * 100,
            points: player.points / gameCount,
            offRebound: player.offRebound / gameCount,
            defRebound: player.defRebound / gameCount,
            reboundTot: player.reboundTot / gameCount,
            assist: player.assist / gameCount,
            turnover: player.turnover / gameCount,
            block: player.block / gameCount,
            steal: player.steal / gameCount,
            personalFoul: player.personalFoul / gameCount,
            eff: player.eff / gameCount,
            gamesPlayed: player.gamesPlayed
        };
        averages.push(avg);
    });
    //fill out the rest of the numbers with 0 for players who have never played in the given season(S)
    if (zeroes) {
        const playerNumbers = averages.map((player) => player.no);
        for (let i = 0; i <= 100; i++) {
            if (!playerNumbers.includes(i)) {
                const zeroAvg: stat = {
                    name: 'no name',
                    no: i,
                    fgMade2pt: 0,
                    fgAtt2pt: 0,
                    fg2pt: 0,
                    fgMade3pt: 0,
                    fgAtt3pt: 0,
                    fg3pt: 0,
                    fgMade: 0,
                    fgAtt: 0,
                    fg: 0,
                    ftMade: 0,
                    ftAtt: 0,
                    ft: 0,
                    points: 0,
                    offRebound: 0,
                    defRebound: 0,
                    reboundTot: 0,
                    assist: 0,
                    turnover: 0,
                    block: 0,
                    steal: 0,
                    personalFoul: 0,
                    eff: 0,
                    gamesPlayed: 0
                };
                averages.push(zeroAvg);
            }
        }
    }

    return averages;
}

const calculateSeasonSingleGameBest = function calculateSeasonSingleGameBest(
    seasons: string[]
): stat[] {
    const boxScores: stat[][] = [];

    type postListType = typeof postList.posts; //wtf typescript is a pain in my ass
    seasons.forEach((season) => {
        postList.posts[season as keyof postListType].forEach((postName) => {
            const post = require('../posts/' + season + '/' + postName + '.json');
            if (post.match) {
                const boxScore = require('../data/boxScores/' + postName + '.json');
                boxScores.push(boxScore);
            }
        });
    });

    const maximums: stat[] = [];

    boxScores.forEach((boxScore) => {
        boxScore.forEach((player) => {
            const foundPlayer = maximums.find(
                (playerMax) => playerMax.name === player.name
            );
            if (foundPlayer === undefined) {
                player.gamesPlayed = 1;
                maximums.push(player);
            } else {
                const foundPlayerIndex = maximums.findIndex(
                    (playerMax) => playerMax.name === player.name
                );
                const max: stat = {
                    name: foundPlayer.name,
                    no: foundPlayer.no,
                    fgMade2pt: Math.max(foundPlayer.fgMade2pt, player.fgMade2pt),
                    fgAtt2pt: Math.max(foundPlayer.fgAtt2pt, player.fgAtt2pt),
                    fg2pt: Math.max(foundPlayer.fg2pt, player.fg2pt),
                    fgMade3pt: Math.max(foundPlayer.fgMade3pt, player.fgMade3pt),
                    fgAtt3pt: Math.max(foundPlayer.fgAtt3pt, player.fgAtt3pt),
                    fg3pt: Math.max(foundPlayer.fg3pt, player.fg3pt),
                    fgMade: Math.max(foundPlayer.fgMade, player.fgMade),
                    fgAtt: Math.max(foundPlayer.fgAtt, player.fgAtt),
                    fg: Math.max(foundPlayer.fg, player.fg),
                    ftMade: Math.max(foundPlayer.ftMade, player.ftMade),
                    ftAtt: Math.max(foundPlayer.ftAtt, player.ftAtt),
                    ft: Math.max(foundPlayer.ft, player.ft),
                    points: Math.max(foundPlayer.points, player.points),
                    offRebound: Math.max(foundPlayer.offRebound, player.offRebound),
                    defRebound: Math.max(foundPlayer.defRebound, player.defRebound),
                    reboundTot: Math.max(foundPlayer.reboundTot, player.reboundTot),
                    assist: Math.max(foundPlayer.assist, player.assist),
                    turnover: Math.min(foundPlayer.turnover, player.turnover),
                    block: Math.max(foundPlayer.block, player.block),
                    steal: Math.max(foundPlayer.steal, player.steal),
                    personalFoul: Math.min(
                        foundPlayer.personalFoul,
                        player.personalFoul
                    ),
                    eff: Math.max(foundPlayer.eff, player.eff),
                    gamesPlayed: foundPlayer.gamesPlayed + 1
                };
                maximums[foundPlayerIndex] = max;
            }
        });
    });

    return maximums;
}

const careerAveragesWithZeroes = calculateCareerAverages(true)
const careerAveragesWithoutZeroes = calculateCareerAverages(false)

const seasonAveragesWithZeroes = new Map<string, stat[]>()
const seasonAveragesWithoutZeroes = new Map<string, stat[]>()
const seasons = getSeasons()

seasons.forEach(season => {
    seasonAveragesWithZeroes.set(season, calculateSeasonAverages([season], true))
    seasonAveragesWithoutZeroes.set(season, calculateSeasonAverages([season], false))
})

const singleGameBests = calculateCareerSingleGameBest()

//calculate everything possible above, set them to variables and just call the variables below
export const StatHelper = {
    getCareerAverages: function getCareerAverages(zeroes: boolean = false): stat[] {
        if (zeroes) {
            return careerAveragesWithZeroes
        } else {
            return careerAveragesWithoutZeroes
        }
    },
    getSeasonAverages: function getSeasonAverages(season: string, zeroes: boolean = false): stat[] {
        if (zeroes) {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return seasonAveragesWithZeroes.get(season)!
        } else {
            // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
            return seasonAveragesWithoutZeroes.get(season)!
        }
    },
    getSingleGameBests: function getSingleGameStats(): stat[] {
        return singleGameBests
    }
};

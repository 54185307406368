const YoutubeHelper = {
    getEmbedHTML: function (url: string, width: number): null | string {
        const height = Math.round((width / 16.0) * 9.0);
        if (!url) {
            return null;
        }
        const videoId = this.getId(url);
        const iframeMarkup =
            '<iframe width="' +
            width +
            '" height="' +
            height +
            '" src="//www.youtube.com/embed/' +
            videoId +
            '" frameborder="0" allowfullscreen></iframe>';
        return videoId === null ? null : iframeMarkup;
    },

    getId: function (url: string) {
        const regExp =
            /^.*(youtu.be\/|v\/|u\/\w\/|embed\/|watch\?v=|&v=)([^#&?]*).*/;
        const match = url.match(regExp);

        return match && match[2].length === 11 ? match[2] : null;
    }
};

export default YoutubeHelper;
